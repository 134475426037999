import React, { useState } from 'react';
import { navigate, Link } from 'gatsby';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SocialNetwork from '../social-networks/social-networks';
import cartImg from '../../../assets/images/carrito.svg'
import './menu.scss';
import ShopModal from '../../atoms/shop-modal/shop-modal';
import { LocalStorage } from '../../../shared/utils/storage';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'

const Menu = props => {
  const { menuActive, dataMenu, modalActive, setmodalActive, category } = props;
  const { instagramUrl, facebookUrl, tiktokUrl, twitterUrl, youtubeUrl, tieneYoutube, tieneInstagram, tieneFacebook, tieneTikTok, tieneTwitter, redireccionRegistrate } = dataMenu;
  const socialNetwork = [
    {url: instagramUrl, isVisible: tieneInstagram}, 
    {url: facebookUrl, isVisible: tieneFacebook}, 
    {url: tiktokUrl, isVisible: tieneTikTok}, 
    {url: twitterUrl, isVisible: tieneTwitter}, 
    {url: youtubeUrl, isVisible: tieneYoutube},
  ];

  let arrMenu = [
    {
      name: 'Home',
      class: 'home',
      redirect: '/',
      subCategory: 'Ninguno',
    },
    {
      name: 'Nosotros',
      class: 'users',
      redirect: '/nosotros/',
      subCategory: 'Somos Familia',
    },
    {
      name: 'Productos',
      class: 'toilet-paper',
      redirect: '/productos/',
      subCategory: 'Todos',
    },
    {
      name: 'Contacto',
      class: 'envelope',
      redirect: '/contacto/',
      subCategory: 'Ninguno',
    },
    {
      name: 'FamiTips',
      class: 'newspaper',
      redirect: '/famitips/',
      subCategory: 'Todos',
    },
    // {
    //   name: 'Ruleta',
    //   class: 'roulette',
    //   redirect: '/ruleta/',
    //   subCategory: 'Ninguno',
    // },
  ];

  

  const datalayerShopButton = () => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Carrito',
      category: 'Productos',
      action: 'click',
      label: 'Carrito',
      location: location,
      user_data: [],
      log_status: '',
    })
    pushDataLayerEvent({
      event: 'buy_now_list_retailers',
      single_item_id: '',
    })
  }

  const datalayerMenuButtons = (categoryName, subCategory, menuActive) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Menú',
      category: categoryName,
      action: 'click',
      label: subCategory || 'Ninguno',
      sub_category: subCategory || 'Ninguno',
      location: location,
      user_data: [],
      log_status: '',
    })
    pushDataLayerEvent({
      event: 'menu_click',
      action_type: 'Link',
      link_text: categoryName+' > '+subCategory,
      index: menuActive+2,
      menu_name: 'Menu principal'
    })
  }

  return (
    <>
      <div className="f-menu-container">
        <div className="f-menu-content">
          <div className="f-menu-navegacion">
            <div
              className="f-shop-button"
              onClick={() => {
                datalayerShopButton();
                setmodalActive(<ShopModal setmodalActive={setmodalActive} />);
              }}
            >
              <img className="f-icon-shop" src={cartImg} alt="Imagen tienda" onClick={()=> datalayerShopButton()}/>
            </div>

            <BottomNavigation
              value={menuActive}
              onChange={(event, newValue) => {
                LocalStorage.removeItem('productOrigin');
                navigate(arrMenu[newValue].redirect);
              }}
              showLabels
            >
              {arrMenu.map((menu, index) => {
                const type = index === menuActive ? 'fas' : 'far';
                const hasRoulette = ['es-CO','es-EC'].includes(process.env.LOCALE)
                
                if(index === 5 && !hasRoulette){
                  return null
                }

                return (
                  <BottomNavigationAction
                    key={index}
                    component={React.forwardRef((props, ref) => (
                      <Link {...props} ref={ref} to={arrMenu[index].redirect} onClick={ () => datalayerMenuButtons(arrMenu[index].name, arrMenu[index].subCategory, index)}>
                        <div className="MuiBottomNavigationAction-wrapper">
                          {index === 1 ? (
                            <div className={`f-menu-bear-${type}-icon`}></div>
                          ) : (
                          index === 5 ? (
                            <div className={`f-menu-roulette-icon`}></div>
                          ) : (
                            <i
                              className={`${type} fa-lg fa-${menu.class} f-color-icon`}
                            ></i>
                          ))}
                          <span>{menu.name}</span>
                        </div>
                      </Link>
                    ))}
                  />
                );
              })}
              <a class="MuiButtonBase-root MuiBottomNavigationAction-root">
                <div className="MuiBottomNavigationAction-wrapper">
                  <i className={`far fa-lg fa-user f-color-icon`}></i>
                  <a
                    onClick={ () => datalayerMenuButtons('Registrate', 'Ninguno', 5) }
                    href={redireccionRegistrate}
                  >
                    Registrate
                  </a>
                </div>
              </a>
            </BottomNavigation>
            <SocialNetwork socialNetworks={socialNetwork}></SocialNetwork>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
