import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../molecules/footer/footer';
import Header from '../../molecules/header/header';
import Menu from '../../molecules/menu/menu';
import favicon from '../../../images/favicon.ico';
import CookieConsent from '../../molecules/cookie-consent/cookie-consent';
import { siteUrl } from '../../../shared/utils/siteUrl';
import Helmet from 'react-helmet';
import './layout.scss';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import PopUpModal from '../../atoms/popUp-modal/popUp-modal';

const Layout = props => {
  const {
    menuActive = null,
    headerActive = true,
    footerActive = true,
    classes = {},
    modalActive,
    setmodalActive,
    category,
    itemSubMenu,
    title,
    slug

  } = props;
  const { main = '', footer = '' } = classes;
  const [isMapPage, setMapPage] = React.useState(false);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [excutionAlertTime60s, setExcutionAlertTime60s] = React.useState(true);

  React.useEffect(() => {
    if (window.location.pathname === '/nosotros/donde-estamos/') {
      setMapPage(true);
    }
  }, []);

  useEffect(() => {
		const modalPopUp = sessionStorage.getItem('modalPopUp')
		if (modalPopUp === null) {
			setIsOpenPopUp(true)
			sessionStorage.setItem('modalPopUp', 'showed')
		}
	}, [])

  useEffect(() => {
    let executionCount = true;

    function getScrollPercentage() {
      let documentElement = document.documentElement;
      let bodyElementt = document.body;
      let st = 'scrollTop';
      let sh = 'scrollHeight';
      let percentage =
        (documentElement[st] || bodyElementt[st]) /
        ((documentElement[sh] || bodyElementt[sh]) -
          documentElement.clientHeight);

      return Number.parseFloat(percentage).toFixed(2);
    }

    const listenScroll = () => {
      let currentScrollY = getScrollPercentage();
      if (currentScrollY >= 0.5 && executionCount && excutionAlertTime60s) {
        executionCount = false;
        setTimeout(() => {
          pushDataLayerEvent({
            event: 'content_consumption',
          });
        }, 60000);
        setExcutionAlertTime60s(false);
      }
    };

    document.addEventListener('scroll', listenScroll);

    return () => {
      document.removeEventListener('scroll', listenScroll);
    };
  }, []);

  useEffect(()=>{
    let location=''
    if (typeof window !== 'undefined') {
      location=window.location.href
    }
    pushDataLayerEvent({
      event: 'Page_View',
      location: location,
      page: slug,
      title: title,
      userId: '',
    })
  },[])

  const resultQuery = useStaticQuery(
    graphql`
      query MENU_QUERY {
        allContentfulMenu {
          nodes {
            node_locale
            id
            youtubeUrl
            twitterUrl
            tiktokUrl
            facebookUrl
            instagramUrl
            tieneInstagram
            tieneFacebook
            tieneTikTok
            tieneTwitter
            tieneYoutube
            termsAndConditions {
              termsAndConditions
            }
            redireccionRegistrate
            politicasDeProteccion {
              file {
                url
              }
            }
            copyright
            customerService
            logo {
              file {
                url
              }
            }
          }
        }
        allContentfulHome {
          nodes {
            node_locale
            tieneModal
            imagenModal {
              title
              storeRedirection
              storeLogo {
                description
                title
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const dataQuery = resultQuery.allContentfulMenu.nodes.filter(e => e.node_locale === process.env.LOCALE)[0];
  const dataModal =resultQuery.allContentfulHome.nodes.filter(e => e.node_locale === process.env.LOCALE)[0];
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {headerActive ? <Header dataHeader={dataQuery} /> : <></>}
      <main className={main}>{props.children}</main>
      {process.env.ENVIRONMENT !== 'PDN' && <span className="country-title">{process.env.COUNTRY_NAME + ' - ' + process.env.ENVIRONMENT}</span>}
      {modalActive}
      {/* <CookieConsent logoUrl={dataQuery.logo.file.url} /> */}
      {footerActive ? (
        <Footer dataFooter={dataQuery} className={footer} isMapPage={isMapPage}></Footer>
      ) : (
        <></>
      )}

      {menuActive != null ? (
        <Menu
          menuActive={menuActive}
          dataMenu={dataQuery}
          setmodalActive={setmodalActive}
          modalActive={modalActive}
          article={props.article}
          category={category}
        ></Menu>
      ) : (
        <></>
      )}
      {dataModal.tieneModal  && isOpenPopUp && (
        <PopUpModal dataModal={dataModal} closeAction={() => {
          setIsOpenPopUp(false)
        }}/>
      )}
    </>
  );
};

export default Layout;
